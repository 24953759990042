.popover__panel-list {
  max-height: 400px;
  scrollbar-color:  #419fde white;
  scrollbar-width: thin;
}

.header-description {
  font-size: 10px;
}

.register-description {
  font-size: 12px;
}

.dropship-checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0;
  cursor: pointer;
}

.dropship {
  width: 25px;
  height: 25px;
  background: white;
  border-radius: 50%;
  border: 1px solid black;
  display: block;
  margin-right: 10px;
}

.dropship-checkbox svg {
  display: none;
}

.dropship-checkbox.active svg {
  display: block;
  margin-top: -1px;
  width: 24px;
  margin-right: -1px;
}

.dropship-wrap {
  position: relative;
}

.dropship-wrap:hover:after{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  background: #444;
  border-radius: 8px;
  color: #fff;
  content: attr(tooltip);
  margin: -68px auto 0;
  font-size: 14px;
  line-height: normal;
  padding: 13px;
  width: 270px;
  left: -35px;
  position: absolute;
  top: 12px;
}

@media(min-width: 576px) {
  .dropship-wrap:hover:after{
    width: 350px;
    left: 0;
  }
}

.dropship-wrap:hover:before{
  border: solid;
  border-color: #444 transparent;
  border-width: 12px 6px 0 6px;
  content: "";
  left: 10%;
  bottom: 30px;
  position: absolute;
}

.dropship-checkbox p {
  margin-bottom: 0;
}
.product-form__inventory.inventory.pre-order {
  color: #e58a03;
}
.case-price-widget .product-form__price-info span {
  text-decoration: underline;
  font-weight: 600;
}
.case-price-widget .product-form__price-info {
  padding-left: 52px;
}
.case-tag-div {
  height: 25px;
}